<template>
  <top :title="'产品中心'" class="titles"/>
  <div class="content">
    <div class="nav-warp">
      <div class="nav">
        <div
          class="nav-item"
          v-for="(item, index) in date"
          :key="index"
          @click="changing(index)"
          :class="{ selectedColor: current == index }"
        >
          {{ item.name }}
          <div :class="{ selecteds: current == index }"></div>
        </div>
      </div>
    </div>
    <div class="products-warp" ref="contentsWarp">
      <div
        class="products wow fadeInUp"
        v-for="(item, index) in paging(date[current]['child'])"
        :key="index"
      >
        <div class="img-warp">
          <img
            :src="
              require('@/assets/productImg/' +
                date[current]['address'] +
                '/' +
                item.img)
            "
            :alt="item.name"
          />
        </div>
        <div class="mes-warp">
          <div class="item-name">
            {{ item.name }}
          </div>
          <div class="shortDescription">
            {{ item.shortDescription }}
          </div>
          <div class="more" @click="openDialogs(item)">
            {{ "查看详情" }}
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      class="page"
      background
      layout="prev, pager, next"
      :page-size="9"
      v-model:current-page="page"
      :total="date[current]['child'].length"
      @current-change="sizeChange"
    />
  </div>
  <div class="tagDialog">
    <el-dialog v-model="dialogVisible" align-center draggable>
      <div class="tagDialogs">
        <div class="dialogLeft">
          <img
            :src="
              require('@/assets/productImg/' +
                date[current]['address'] +
                '/' +
                dialogData.detailImg)
            "
            :alt="dialogData.name"
          />
        </div>
        <div class="dialogRight">
          <div class="tagTitles">
            {{ dialogData.name }}
          </div>
          <div class="tagMes">
            {{ dialogData.describe }}
          </div>
          <div class="tagMerit" v-if="dialogData.merit">
            <div
              class="tagMeritItem"
              v-for="(item, index) in dialogData.merit"
              :key="index"
            >
              <img :src="require('@/assets/gou.png')" />
              <div class="merits">{{ item }}</div>
            </div>
          </div>
          <div class="tagMerit" v-else>
            <div class="parametersTitle">{{ "基本参数：" }}</div>
            <div class="parameters" v-html="dialogData.parameter"></div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>

  <common-footer></common-footer>
</template>
<script>
import top from "@components/top.vue";
// import tags from "./tags";
import $ from "jquery";
import CommonFooter from "@components/footer.vue";
import { mapMutations } from "vuex";
import productDate from "../../store/productDate.json";
export default {
  components: {
    top,
    // tags,
    CommonFooter,
  },
  data() {
    return {
      current: 0,
      date: productDate,
      page: 1,
      dialogVisible: false,
      dialogData: {},
      dialogWeight: "10rem",
    };
  },
  created() {
    this.current = this.$route.query.current || 0;
  },
  methods: {
    paging(arr = []) {
      return arr.slice((this.page - 1) * 9, this.page * 9);
    },
    changing(index) {
      this.current = index;
      this.page = 1;
      $(window).scrollTop(this.$refs.contentsWarp.offsetTop - 80);
    },
    openDialogs(item) {
      this.dialogData = item;
      this.dialogVisible = true;
    },
    sizeChange() {
      window.scrollTo(0, 400);
    },
    ...mapMutations([
      "scrollToSection",
      "setHeaderColorBlack",
      "setHeaderColorWhite",
      "setheaderTopBtnWhite",
    ]),
  },
  mounted() {
    this.scrollToSection(this.$route.hash);
    this.setheaderTopBtnWhite(false);
  },
  unmounted() {
    this.setheaderTopBtnWhite(false);
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .nav-warp {
    width: 100%;
    height: 100%;
    position: sticky;
    top: 0;
  }
  .nav {
    width: 100%;
    height: 0.82rem;
    flex-shrink: 0;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    @if ($searchWidth<1100) {
      height: 3.4rem;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .nav-item {
      display: flex;
      flex-direction: column;
      font-size: 0.2rem;
      width: 2.2rem;
      justify-content: center;
      align-items: center;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #b6c1c0;
      box-sizing: border-box;
      position: relative;
      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        width: 25%;
      }
      .selecteds {
        position: absolute;
        bottom: 0;
        background: #5ec7b9;
        width: 0.9rem;
        height: 4px;
        @if ($searchWidth<1100) {
          width: 1.6rem;
          height: 2px;
        }
      }
    }
    .selectedColor {
      color: #333333;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    }
  }
  .content {
    width: 100%;
    background: #f8f9fb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .products-warp {
      @if ($searchWidth<1100) {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
      } @else {
        margin-top: 0.8rem;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-template-rows: repeat(3,1fr);
        grid-gap:0.4rem;
        width: 13.2rem;
      }
      .products {
        background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
        border-radius: 10px;
        @if ($searchWidth<1100) {
          width: calc(100% - 32px);
          margin: 0 16px 40px;
        } @else {
          .img-warp {
            overflow: hidden;
            border-radius: 10px 10px 0 0;
          }
        }
        img {
          width: 100%;
          display: block;
          @if ($searchWidth>1100) {
            &:hover {
              transform: scale(1.2);
              transition: all 0.2s;
            }
          }
        }
        .mes-warp {
          padding-left: 0.3rem;
          margin-top: 0.2rem;
          @if ($searchWidth<1100) {
            margin-top: 18px;
            padding-left: 27px;
          }
        }
        .item-name {
          font-size: 0.24rem;
          font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
          line-height: 0.3rem;
          @if ($searchWidth<1100) {
            font-size: 1rem;
            line-height: 1.2rem;
          }
        }
        .shortDescription {
          margin-top: 0.1rem;
          margin-bottom: 0.3rem;
          font-size: 0.16rem;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
          line-height: 25px;
          @if ($searchWidth<1100) {
            font-size: 0.7rem;
            line-height: 1rem;
            margin-top: 8px;
            margin-bottom: 21px;
          }
        }
      }
    }
    .page::v-deep {
      margin: 0.8rem 0;
      .el-pager {
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        .is-active {
          background-color: #5ec7b9 !important;
          color: #ffffff;
        }
        .number {
          color: #7a7a7a;
          font-size: 16px;
          font-weight: normal !important;
        }
      }
      .btn-next,
      .btn-prev {
        i {
          font-size: 16px !important;
        }
      }
      .btn-next:hover:not(:disabled),
      .btn-prev:hover:not(:disabled) {
        background-color: #5ec7b9 !important;

        i {
          color: #fff !important;
        }
      }
    }
  }

  .more {
    background: #5ec7b9;
    text-align: center;
    //display: inline-block;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #77e0d2;
    }
    @if ($searchWidth<1100) {
      height: 2rem;
      width: 2.4rem;
      line-height: 33px;
      border-radius: 7px;
      font-size: 0.6rem;
      margin-bottom: 0.6rem;
      padding: 0 1.2rem;
    } @else {
      height: 0.46rem;
      width: 1.2rem;
      border-radius: 0.08rem;
      font-size: 0.16rem;
      margin-bottom: 0.3rem;
    }
  }

  @if ($searchWidth<1100) {
    .m-f1 {
      margin-bottom: -3.2rem;
    }
  }

  .tagDialogs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @if ($searchWidth<1100) {
      flex-direction: column;
      height: calc(100% - 30px);
    }
    .dialogLeft {
      align-self: flex-start;
      display: flex;
      align-items: center;
      width: 500px;
      @if ($searchWidth<1100) {
        width: 100%;
        height: 50%;
        img {
          margin: 0 auto;
          height: 100%;
        }
      } @else {
        img {
          margin: 0 auto;
          margin-top: 15px;
          width: 330px;
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
    }

    .dialogRight {
      flex: 1;
      align-self: flex-start;
      padding-right: 20px;
      height: 100%;
      overflow: auto;
      @if ($searchWidth<1100) {
        padding: 0 20px;
      }
      .tagTitles {
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        line-height: 42px;
      }
      .tagMes {
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        margin: 10px 0 25px 0;
        font-size: 16px;
        font-weight: normal;
        color: #333333;
        line-height: 25px;
      }
      .tagMerit {
        padding-bottom: 5px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        .tagMeritItem {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          img {
            width: 18px;
            height: 18px;
          }
          .merits {
            margin-left: 20px;
            font-size: 16px;
            font-weight: normal;
            color: #7a7a7a;
            line-height: 25px;
          }
        }
      }
      .parametersTitle {
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: normal;
        color: #333333;
        line-height: 25px;
      }
      .parameters {
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        white-space: pre-wrap;
        font-size: 16px;
        font-weight: normal;
        color: #7a7a7a;
        line-height: 28px;
      }
    }
    .dialogRight::-webkit-scrollbar {
      width: 4px;
    }
    .dialogRight::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .titles {
    :deep(.t2) {
      width: 3.6rem;
      @if ($searchWidth<1100) {
        width: 12rem;
      }
    }
  }
  .tagDialog {
    :deep(.el-dialog__body) {
      height: 440px;
      padding: 30px 0 0;
      @if ($searchWidth<1100) {
        height: 100%;
        padding: 0;
      }
    }
    :deep(.el-dialog) {
      border-radius: 10px;
      width: 1000px;
      height: 500px;
      @if ($searchWidth<1100) {
        width: 80%;
        height: 70%;
      }
    }

    :deep(.el-dialog__headerbtn) {
      background: url("~@/assets/dialogClose.png");
      background-size: cover;
      width: 30px;
      height: 30px;
      top: 15px;
      right: 15px;
      i {
        display: none;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
